<!--
 * @Descripttion: 电视墙首页
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-03 09:46:25
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-05-17 10:20:13
-->
<template>
  <div v-loading="isLoading" class="tvwall-box">
    <el-carousel v-if="tvWallCircle.length" indicator-position="none" :initial-index="0" arrow="never" :autoplay="false" :loop="true" ref="carouselRef">
      <el-carousel-item v-for="({index, url, type, topic_list, likes_count}, indexItem) in tvWallCircle" :key="indexItem">
        <el-image v-if="type == 1" :src="url" class="wall-img"></el-image>
        <p v-else-if="type == 2" class="video-container">
          <video :src="url" preload muted :loop="videoLoop" class="video-box" :data-index="index"></video>
          <span @click="setVideoMuted">
            <el-icon v-show="isMute"><Mute /></el-icon>
            <el-icon v-show="!isMute"><Microphone /></el-icon>
          </span>
        </p>
        <DynamicWall
          :ref="getDynamicRef"
          :title="{topic_list, likes_count}"
          :id="index"
          :dynamicIndex="indexItem"
          v-else-if="type == 3"
        ></DynamicWall>
        <iframe
          v-else-if="type == 4"
          id="WallIfram"
          name="WallIfram"
          width="100%"
          height="100%"
          :src="url"
          >
        </iframe>
      </el-carousel-item>
    </el-carousel>
  </div>
  <empty-data v-if="!isLoading && isEmptyElements"
    image="no-content"
    description="链接已失效"
    ></empty-data>
</template>
<script>
import { reactive, toRefs, onMounted, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import mySocket from '@/mixin/socket.js'
import { loadImages, loadVideos } from '@/utils/tools.js'
import { getRuleInfo, setRuleLoaded } from '@/apis/tvwall.js'
import DynamicWall from './components/DynamicWall.vue'
import { dialogMsg } from '@/utils/dialogMsg.js'
export default {
  components: {
    DynamicWall
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      isLoading: true,
      tvWallList: computed(() => [...store.state.tvwall.tvWallList]),
      tvWallCircle: [], // 轮播总素材
      normalElements: [], // 正常轮播素材
      jumpElements: [], // 排队轮播素材
      isEmptyElements: computed(() => store.state.tvwall.isRuleRevoke[route.params?.id]), // 空组件显示
      elementIndexs: computed(() => state.tvWallCircle.map(i => i.index)),
      isPreloadImg: false,
      isPreloadVideo: false,
      carouselRef: null,
      currentIndex: 0, // 当前轮播索引
      initFlag: false, // 电视墙初始访问进入标识 false-已访问 true-未访问
      initElementFlag: false,
      dynamicRefs: [],
      videoLoop: false,
      jumpArr: [],
      isMute: true
    })

    // 添加websocket
    const { data, connectSocket } = mySocket()
    connectSocket()
    data.socketServe.registerCallBack('callback1', data.socketServe)
    watch(() => state.isEmptyElements, (newValue) => {
      newValue && (state.tvWallCircle = [])
    })
    onMounted(() => {
      getRuleData()
    })
    const getRuleData = async () => {
      // debugger
      if (!route.params?.id) {
        state.isLoading = false
        store.commit('tvwall/setRuleRevoke', { id: route.params?.id, value: true })
        return false
      }
      const { code, data } = await getRuleInfo({ id: route.params?.id })
      if (code === 0) {
        // 获取设置时间素材
        state.jumpElements = data.jump_elements
        const elementList = [...data.normal_elements, ...data.jump_elements]
        // 正常素材数量为2时，复制一份; 正常素材数量为1，且含有排队素材，复制一份;解决轮播少于2的时候出现轮播方向问题
        if (data.normal_elements.length === 2 || (data.normal_elements.length === 1 && data.jump_elements.length)) {
          data.normal_elements = [...data.normal_elements, ...data.normal_elements]
        }
        if (data.normal_elements.length === 1 && data.normal_elements[0].type === 2) {
          state.videoLoop = true
        }

        state.normalElements = data.normal_elements
        // type  1-图片 2-视频 3-动态墙 4-链接
        const imgs = elementList.filter(item => item.type === 1).map(i => i.url)
        const videos = elementList.filter(item => item.type === 2).map(i => i.url)
        if (imgs.length) {
          // 存在图片素材进行预加载
          loadImages(imgs, () => {
            // 加载完毕回调
            state.isPreloadImg = true
          })
        } else {
          state.isPreloadImg = true
        }

        if (videos.length) {
          // 存在视频素材进行预加载
          loadVideos(videos, () => {
            // 加载完毕回调
            state.isPreloadVideo = true
          })
        } else {
          state.isPreloadVideo = true
        }
      } else {
        state.isLoading = false
        store.commit('tvwall/setRuleRevoke', { id: route.params?.id, value: true })
      }
    }
    const getDynamicRef = (el) => {
      if (el && !state.dynamicRefs.includes(el)) {
        state.dynamicRefs.push(el)
      }
    }
    const setVideoMuted = () => {
      state.isMute = !state.isMute
      document.querySelectorAll('.video-box').forEach(i => {
        i.muted = state.isMute
      })
    }

    // 获取视频播放
    const playVideo = (index, time) => {
      nextTick(() => {
        const videoBox = document.querySelectorAll(`.video-box[data-index='${index}']`)
        // 存在复制情况，含有同个index，找到对应的视频
        const indexEl = (videoBox.length > 1 && state.currentIndex > 1) ? 1 : 0
        videoBox[indexEl].pause()
        videoBox[indexEl].currentTime = time
        const clearFour = setTimeout(() => {
          videoBox[indexEl].play()
          clearTimeout(clearFour)
        }, 150)
      })
    }
    const switchFeed = (data) => {
      const clearOne = setTimeout(() => {
        store.commit('tvwall/addFeedsData', { index: data.index, feed_info: data.feed_info })
        clearTimeout(clearOne)
      }, 500)
    }

    // 监听资源加载是否完毕
    watch(() => [state.isPreloadImg, state.isPreloadVideo], (newValue) => {
      if (newValue[0] && newValue[1]) {
        state.tvWallCircle = state.normalElements
        setRuleLoaded({ id: route.params.id }).then(({ code, data, msg }) => {
          if (code === 0) {
            state.initFlag = !data.current_element?.id
            nextTick(() => {
              // 处理下一项推送刚好处于资源加载完毕接口结束时间
              if (!state.initFlag) {
                // eslint-disable-next-line camelcase
                const { index, type, seconds_played, feed_info, url } = data.current_element
                const initData = { index, type, seconds_played, feed_info, url }
                if (state.initElementFlag) return
                store.commit('tvwall/addTVWallList', initData)
              }
            })
          } else {
            dialogMsg('error', msg)
          }
        })
      }
    })

    const nextNormalItem = (index) => {
      state.carouselRef.setActiveItem(index)
      state.currentIndex = index
    }
    const nextJumpItem = (index, insertIndex, isFeed, type) => {
      const insertItem = state.jumpElements.find(i => i.index === index)
      if (!insertItem) return false
      state.tvWallCircle.splice(insertIndex, 0, insertItem)
      state.jumpArr.push(insertIndex)
      const clearSix = setTimeout(() => {
        isFeed && state.dynamicRefs.forEach(i => {
          i.getWallTitle()
        })
        type === 'init' || state.carouselRef.next()
        clearTimeout(clearSix)
      }, 200)
      return true
    }

    watch(() => state.tvWallList, (newValue, oldValue) => {
      // debugger
      if (oldValue.length > newValue.length) return
      const newData = newValue[newValue.length - 1]
      if (newValue.length >= 200) store.commit('tvwall/setTVWallList', [])
      // eslint-disable-next-line camelcase
      const { index, feed_info, type, seconds_played } = newData // index-素材标识 feed_info-动态详情 type-素材类型 seconds_played-视频已过时间
      if (!state.initElementFlag) {
        // 初始化
        const initIndex = state.elementIndexs.indexOf(index)
        if (initIndex > -1) {
          nextNormalItem(initIndex)
        } else {
          const isFlag = nextJumpItem(index, 0, type === 3, 'init')
          if (!isFlag) return false
        }
        // eslint-disable-next-line camelcase
        type === 2 && playVideo(index, seconds_played || 0)
        type === 3 && switchFeed({ index, feed_info })

        state.initElementFlag = true
        const clearTwo = setTimeout(() => {
          state.isLoading = false
          clearTimeout(clearTwo)
        }, 100)
        return false
      }
      const currentData = state.tvWallCircle[state.currentIndex]
      if (currentData.index === index) {
        type === 3 && store.commit('tvwall/addFeedsData', { index, feed_info }) // 动态上墙
        type === 2 && playVideo(index, 0) // 循环播放
      } else {
        // 切下一项
        next(newData, currentData)
      }
    })

    const next = (nextValue, currValue) => {
      // debugger
      const nextIndex = (state.currentIndex <= state.tvWallCircle.length - 2) ? (state.currentIndex + 1) : 0
      // 判断是否有排队素材上墙
      if (!nextIndex && state.jumpArr.length) {
        // 一轮过滤删除插队的素材
        state.tvWallCircle = state.tvWallCircle.filter((item, index) => !state.jumpArr.includes(index))
        state.jumpArr = []
        nextTick(() => {
          state.dynamicRefs.forEach(i => {
            i.getWallTitle()
          })
        })
        state.currentIndex = 0
      }
      if (state.tvWallCircle[nextIndex].index === nextValue.index) {
        // 对应下一项素材元素
        nextNormalItem(nextIndex)
      } else {
        const isFlag = nextJumpItem(nextValue.index, state.currentIndex + 1, nextValue.type === 3)
        if (!isFlag) return false
        state.currentIndex = state.currentIndex + 1
      }
      // 初始化上一个轮播过的动态墙
      if (currValue.type === 3) {
        const clearFive = setTimeout(() => {
          state.dynamicRefs.filter(i => i.id === currValue.index).forEach(item => {
            item.resetFeedList()
          })
          clearTimeout(clearFive)
        }, 1500)
      }
      // 切换后，进行动态上墙或者视频播放
      nextValue.type === 2 && playVideo(nextValue.index, nextValue.seconds_played || 0)
      nextValue.type === 3 && switchFeed(nextValue)
    }

    return {
      ...toRefs(state),
      getDynamicRef,
      setVideoMuted
    }
  }
}

</script>
<style lang="less" scoped>
.el-empty{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.tvwall-box,
.el-carousel,
:deep(.el-carousel__container){
  width: 100%;
  height: 100%;
}
:deep(.el-carousel__item) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.wall-img.el-image){
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .el-image__inner{
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
}
.video-container{
  position: relative;
  .el-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: 5px;
    border: 1px #999;
    svg {
      width: 30px;
      height: 30px;
      }
  }
}
.video-box{
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;
}
</style>
