<!--
 * @Descripttion: 动态墙
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-20 14:01:55
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-05-28 14:53:59
-->
<template>
  <div class="dynamic-wall">
    <canvas :id="`wall-bg-${dynamicIndex}`" class="wall-bg"></canvas>
    <Vscreensize :width="initWidth" :height="initHeight" :delay="0">
      <div class="wall-box">
        <div class="wall-title">
          <img src="@/assets/img/prize/logo.png" alt="logo">
          <div class="title-content" v-html="wallTitle"></div>
        </div>
        <div :class="['wall-content', isPortraitMode ? 'portrait-mode' : '' ]">
          <ul v-if="feedsList.length" class="wall-desc">
            <li v-for="(item, index) in feedsList" :key="index" :class="{'current': currentFeeds.includes(index)}">
              <div class="feed-content">
                <UserAvatar
                  :user="item?.user"
                  :size="!isPortraitMode ? '40' : '60'"
                  :fontSize="!isPortraitMode ? '14' : '20'"
                  :isWear="false"
                  :noPreview="false"
                  :animation="true"
                >
                  <template #slot>
                      <p class="user-name">{{item?.user?.name}}</p>
                  </template>
                </UserAvatar>
                <div class="feed-desc">
                    <!-- 动态 -->
                    <p
                      v-if="!item?.forward_type && item?.feed_content"
                      class="content-text"
                      v-html="item.feed_content ? analyzeEmoji(formatHtml(item.feed_content)) : ''"
                    ></p>
                    <!-- 通过分享帖子或分享公告、中奖名单发的动态 -->
                    <p
                      v-if="
                      (item?.forward_type && item.feed_content.split('</br></br>')[1])
                       || (item?.forward_type === 10 && item.feed_content)"
                      class="content-text"
                      v-html="
                        item.feed_content
                          ? analyzeEmoji(formatHtml(item.feed_content.split('</br></br>')[1] || item.feed_content))
                          : ''
                      "
                    ></p>
                    <!-- 分享帖子或分享公告、中奖名单标题 -->
                    <p
                      v-if="item?.forward_type"
                      class="content-text"
                      v-html="item.forward_title ? `<br/>${analyzeEmoji(item.forward_title)}${item.forward_type === 10 ? ' - 中奖名单>>' : ''}`: ''"
                      >
                    </p>
                  </div>
              </div>
              <div v-if="item?.image_url || item?.video_url" class="img-box">
                <el-image class="feed-img" :src="item.image_url ? `${item.image_url.split('^')[0]}?x-oss-process=image/resize,w_${!isPortraitMode?'160':'180'}/quality,Q_30` : `${item.video_url}?x-oss-process=video/snapshot,t_0,f_jpg,w_${!isPortraitMode?'160':'180'}`"></el-image>
              </div>
            </li>
          </ul>
          <div v-else class="wall-empty">
            <img src="@/assets/img/prize/wall-gift.png" alt="gift" >
            <p>一大波动态正在赶来的路上...</p>
          </div>
        </div>
      </div>
    </Vscreensize>
    <div id="ContentHeight" :style="{ '--width': `${contentWidth}px`}"></div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed, watch, nextTick, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import Vscreensize from '@/utils/screen/Vscreensize.js'
import { setBg } from '@/utils/screen/screenBg'
import { formatDate, analyzeEmoji } from '@/utils/filterHtml.js'

export default {
  components: {
    Vscreensize
  },
  props: {
    title: {
      type: Object,
      default: () => ({})
    },
    id: { // 动态墙id标识
      type: Number,
      default: 0
    },
    dynamicIndex: { // 动态墙索引，标识每个组件
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      feedsData: computed(() => store.state.tvwall.feedsData[props.id] || []),
      feedsList: [], // 获取动态列表,
      initWidth: 2048, // 初始化屏幕缩放尺寸（宽度）
      initHeight: 1080, // 初始化屏幕缩放尺寸（高度）
      currentFeeds: [], // 当前展示动态index
      contentWidth: 1554,
      exceHeight: -1, // 动态墙初始剩余高度
      wallTitle: '',
      isPortraitMode: false,
      clearSeven: null,
      clearOne: null
    })

    onMounted(() => {
      // 判断是否竖屏
      state.isPortraitMode = isScreenPortraitMode()
      // 配置背景特效效果
      setBg(document.querySelector(`#wall-bg-${props.dynamicIndex}`))
      // 监听屏幕分辨率
      window.onresize = () => {
        return (() => {
          state.isPortraitMode = isScreenPortraitMode()
        })()
      }
      getWallTitle()
    })
    // 获取动态墙标题
    const getWallTitle = () => {
      let topicsContent = ''
      let likeContent = ''
      if (props.title?.topic_list) {
        let topics = ''
        props.title.topic_list.forEach((item, index) => {
          topics += `<b>#${item}#</b>${props.title.topic_list.length !== index + 1 ? ' 或 ' : ''}`
        })
        topicsContent = `包含话题 ${topics} 的`
      }
      if (props.title.likes_count) {
        likeContent = `${props.title?.topic_list ? '，' : ''}且点赞数达到<b>“${props.title.likes_count}”</b>`
      }
      state.wallTitle = `登录“麦风之家”，发送${topicsContent}动态${likeContent}即可上墙`
    }

    // 获取动态内容高度
    const getFeedHeight = (data) => {
      if (!data) return
      // 视频图片展示宽度
      const mediaWidth = (data?.video_url || data?.image_url) ? 0 : (!state.isPortraitMode ? 200 : 220)
      state.contentWidth -= mediaWidth
      let textContent = ''
      // forward_type 2-公告 5-帖子 10-中奖名单
      if (data.forward_type === 2 || data.forward_type === 5) {
        textContent = data.feed_content.split('</br></br>')[1] + `${data?.feed_content.split('</br></br>')[1] ? '</br></br>' : '</br>'}${data.forward_title}`
      } else if (data.forward_type === 10) {
        textContent = data.feed_content + `${data.feed_content ? '</br></br>' : '</br>'}${data.forward_title}`
      } else {
        textContent = data.feed_content
      }
      const feedHeight = document.querySelector('#ContentHeight')
      feedHeight.innerHTML = textContent
      const totalHeight = feedHeight.offsetHeight + (!state.isPortraitMode ? 112 : 140)
      data.feedHeight = totalHeight
      return data
    }

    // 获取动态数据
    watch(() => state.feedsData.length, (newValue, oldValue) => {
      oldValue = oldValue || 0
      if ((oldValue >= newValue)) return
      const newData = getFeedHeight(state.feedsData[state.feedsData.length - 1])
      state.feedsList.push(newData)
      // 只计算一次
      nextTick(() => {
        if (state.exceHeight === -1) {
          state.exceHeight = document.querySelector('.wall-desc')?.offsetHeight || 800
          state.contentWidth = document.querySelector('.wall-desc')?.offsetWidth
        }
        scrollFeed(newData)
      })
    })

    // 更新动态滚动数据
    const scrollFeed = (data) => {
      if (!data) return
      let lastIndex = state.currentFeeds.slice(-1).length ? state.currentFeeds.slice(-1)[0] : -1
      if (data.feedHeight <= state.exceHeight) { // 当前数据高度小于剩余高度,直接显示
        state.clearSeven = setTimeout(() => {
          state.currentFeeds.push(lastIndex + 1)
          state.exceHeight -= data.feedHeight // 更新多余高度
          clearTimeout(state.clearSeven)
        }, 100)
      } else {
        const scrollH = state.feedsList[state.currentFeeds[0]].feedHeight

        // 上滚动态高度大于第一个显示动态和剩余高度之和，顶掉两个显示动态
        const isFlag = data.feedHeight >= state.exceHeight + scrollH
        state.exceHeight += (scrollH + ((isFlag && state.currentFeeds[1]) ? state.feedsList[state.currentFeeds[1]].feedHeight : 0))
        // 切割过多dom
        if (lastIndex > 105) {
          resetFeedList(100)
          lastIndex -= 100
        }
        state.currentFeeds = state.currentFeeds.slice(isFlag ? 2 : 1)
        state.clearOne = setTimeout(() => {
          state.currentFeeds.push(lastIndex + 1)
          state.exceHeight -= data.feedHeight
          clearTimeout(state.clearOne)
        }, 200)
      }
    }

    const resetFeedList = (value) => {
      value = value || state.feedsList.length
      state.currentFeeds = []
      state.feedsList = state.feedsList.slice(value)
      store.commit('tvwall/setFeedList', { index: props.id, feed_info: state.feedsData.slice(value) })
      nextTick(() => {
        state.exceHeight = document.querySelector('.wall-desc')?.offsetHeight || 800
      })
    }

    // 是否竖屏模式
    const isScreenPortraitMode = () => {
      // 获取窗口的宽度和高度
      var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      // 比较窗口的宽度和高度，判断是否处于竖屏模式
      if (windowHeight > windowWidth) {
        // 竖屏，左右上下各留白60
        state.initWidth = windowWidth - 120
        state.initHeight = windowHeight - 120
        return true
      } else {
        // 横屏保留原来的逻辑
        state.initWidth = (windowWidth < 1300 ? 1280 : 2048)
        state.initHeight = (windowWidth < 1300 ? 1024 : 1080)
        return false
      }
    }

    onBeforeUnmount(() => {
      state.clearSeven = null
      state.clearOne = null
      clearTimeout(state.clearSeven)
      clearTimeout(state.clearOne)
    })

    return {
      ...toRefs(state),
      formatDate,
      analyzeEmoji,
      getWallTitle,
      resetFeedList
    }
  }
}
</script>

<style lang="less" scoped>
  #ContentHeight{
    width: var(--width);
    min-height: 102px;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    z-index: -1;
    word-break: break-word;
    line-height: 48px;
    font-size: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  .dynamic-wall{
    position: relative;
    height: 100%;
    width: 100%;
  }
  .wall-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  .v-screen-box::before,
  .v-screen-box::after{
    content: '';
    position: absolute;
  }
  .v-screen-box::before {
    width: 800px;
    height: 642px;
    top: 0;
    left: 0;
    background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/bg-left.png) no-repeat top left/100% 100%;
  }
  .v-screen-box::after {
    width: 700px;
    height: 675px;
    bottom: 0;
    right: 0;
    background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/bg-right.png) no-repeat bottom right/100% 100%;
  }
  .wall-box{
    position: relative;
    padding: 50px 30px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wall-title{
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    img{
      width: 270px;
    }
    .title-content{
      word-break: break-word;
      font-size: 30px;
      line-height: 45px;
      font-weight: 600;
      color: #02050E;
      background: linear-gradient(90.14deg, #EEFAFF 0%, #95D2FF 100%);
      border-radius: 16px;
      padding: 10px 25px 10px 20px;
      margin-left: 33px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: -35px;
        width: 0;
        transform: translateY(-50%);
        height: 0;
        border-top: 15px solid transparent;
        border-right: 25px solid #EEFAFF;
        border-bottom: 15px solid transparent;
        border-left: 15px solid transparent;
        background: linear-gradient(90.14deg, #EEFAFF 0%, #95D2FF 100%);
      }
    }
    :deep(b){
      font-weight: 900;
      font-size: 30px;
      color: #FFFFFF;
      text-shadow: #3377FF 2px 0 0, #3377FF 0 2px 0, #3377FF -1px 0 0, #3377FF 0 -1px 0;
    }
  }
  .wall-content{
    width: 100%;
    height: calc(100% - 180px);
    background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/wall-content-bg.png) no-repeat center/100% 100%;
    padding: 30px;
    overflow: hidden;
    &.portrait-mode{
      background: url(../../../assets/img/prize/bg-portrait-mode.png) no-repeat center / 100% 100%;
    }
  }
  .wall-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    img{
      width: 320px;
      height: 320px;
    }
    p{
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 40px;
    }
  }
  .wall-desc{
    height: 100%;
    position: relative;
    pointer-events: none;
    li{
      width: 100%;
      height: 0;
      border-radius: 20px;
      position: relative;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      border: none;
      transition: opacity 0.3s ease-in, padding 0.3s ease-in, border 0.3s ease-in;
      &.current{
        padding: 30px 40px;
        margin-bottom: 20px;
        height: auto;
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.2);
        .img-box{
          display: flex;
        }
        .feed-content {
          display: inline-block;
          flex: 1;
        }
      }
    }
  }

  .feed-content {
    display: none;
    word-break: break-all;
    // pointer-events: none;
    .feed-desc{
      min-height: 102px;
      max-height: 288px;
      overflow: hidden;
      word-break: break-word;
      display: inline-block;
      font-size: 34px;
      line-height: 48px;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
    :deep(.user-avatar){
      margin: 0 0 18px 0;
      justify-content: flex-start;
      align-items: center;
      .el-image{
        margin: 0 !important;
      }
      &>p:nth-child(1){
        margin: 0 !important;
      }
      .user-name{
        font-weight: 700;
        font-size: 35px;
        line-height: 42px;
        color: #FFFFFF;
        margin-left: 20px;
      }
    }
    .content-text {
      :deep(a) {
        color: #3377FF;
        display: inline-block;
      }
    }
    .emoji-img,.content-text :deep(p>img) {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

  }
  .img-box{
    display: none;
  }
  .feed-img{
    min-width: 180px;
    min-height: 180px;
    margin-left: 40px;
    :deep(.el-image__inner){
      width: 180px;
      height: 180px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  @media (max-width: 1024px) {
    .wall-title img {
      width: 100px;
    }
  }

  @media (max-width: 1300px) {
    .wall-box{
      padding: 35px 0;
    }
  }

  @media (min-width: 2048px) {
    .wall-box{
      padding: 50px 160px 0;
    }
  }

  // @media (max-width: 1300px) {
  //   #ContentHeight{
  //     line-height: 27px;
  //     font-size: 18px;
  //     -webkit-line-clamp: 9;
  //     min-height: 110px;
  //   }
  //   .v-screen-box::before{
  //     width: 700px;
  //     height: 561px;
  //   }
  //   .v-screen-box::after{
  //     width: 600px;
  //     height: 578px;
  //   }
  //   .wall-box{
  //     padding: 35px 0;
  //   }
  //   .wall-title {
  //     // width: 1032px;
  //     margin-bottom: 40px;
  //   }
  //   .wall-content{
  //     width: 1032px;
  //     height: 804px;
  //     background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/wall-content-bg-small.png) no-repeat center/100% 100%;
  //     padding: 24px;
  //     li{
  //       &.current{
  //         padding: 20px 29px;
  //       }
  //     }
  //   }
  //   .feed-content{
  //     .feed-desc{
  //       min-height: 110px;
  //       max-height: 270px;
  //       font-size: 18px;
  //       line-height: 27px;
  //       -webkit-line-clamp: 9;
  //     }
  //     :deep(.user-avatar){
  //       margin: 0 0 10px 0;
  //       .user-name{
  //         font-size: 24px;
  //         line-height: 36px;
  //         margin-left: 12px;
  //       }
  //     }
  //   }
  //   .feed-img{
  //     min-width: 160px;
  //     min-height: 160px;
  //     :deep(.el-image__inner){
  //       width: 160px;
  //       height: 160px;
  //     }
  //   }

  // }
</style>
