/*
 * @Descripttion: 电视墙api
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-07-03 09:51:34
 * @LastEditors: tangjz
 * @LastEditTime: 2023-07-03 09:51:48
 */

import { get, post } from '@/apis/method'

const getRuleInfo = (data) => get('/api/tvwall/rule_info', data) // 获取电视墙规则素材
const setRuleLoaded = (data) => post('/api/tvwall/rule_loaded', data) // 素材资源加载完毕通知

export {
  getRuleInfo,
  setRuleLoaded
}
